import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery, Link} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';

const Case11 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/11.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      return (
        <div className={classes.case} id="cu">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>
              Use Case #11. Cloud control unit
            </h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            You have a POS or server that wants to send data to a cloud control
            unit.
          </p>
          <Img fluid={img} style={{maxWidth: 758}} />
          <p style={{marginTop: 60}}>
            <span style={{wordBreak: 'break-word' }}>
              {'POST /{sellerId}/registration/{cashRegisterName}/kd'}
            </span>{" "}
            or{" "}
            <span style={{wordBreak: 'break-word' }}>
              {'POST /{sellerId}/registration/{cashRegisterName}/kd'}
            </span>
            , sample #1 and sample #2 to send data to the control unit
            (equivalent of method “kd” from
{" "}
            <a href="https://drive.google.com/file/d/0B_VI-5fZqbOVaWZYMDZaOGkwVGs/view">
              <span className={classes.textColor}>
                the PosPlus documentation
              </span>
            </a>
            ).{" "}
            <Link to="/developers/api-key/">
              <span className={classes.textColor}>API keys</span>
            </Link>{" "}
            page describes how to get authenticated.
          </p>
        </div>
      );
    }}
  />
);

Case11.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case11);
